/* eslint-disable */
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//frontend
const Home = lazy(() => import('./components/frontend/Home'));
const About = lazy(() => import('./components/frontend/About'));
const Loan = lazy(() => import('./components/frontend/Loan'));
const Insurance = lazy(() => import('./components/frontend/Insurance'));
const Dealers = lazy(() => import('./components/frontend/Dealers'));
const Contact = lazy(() => import('./components/frontend/Contact'));
const InquiryForm = lazy(() => import('./components/application/InquiryForm'));
const SearchCustomers = lazy(() => import('./components/customers/Search')); 
const PrintPreview = lazy(() => import('./components/admin/Inquiry/PrintPreview'));
//Backend
const Dashboard = lazy(() => import('./components/admin/Dashboard'));
const DealerLogin = lazy(() => import('./components/dealerLogin/Login')); 


const routing = (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <React.Fragment>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/loan" component={Loan} />
        <Route exact path="/insurance" component={Insurance} />
        <Route exact path="/dealers" component={Dealers} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/applyHP" component={InquiryForm} />
        <Route exact path="/applyLoan" component={InquiryForm} />
        <Route exact path="/financeSystem" component={Dashboard} />
        <Route exact path="/checkCustomerRepayment" component={SearchCustomers} /> 
        <Route exact path="/dealerLogin" component={DealerLogin} /> 
        <Route exact path="/financeSystem/printPreview" component={PrintPreview} />  
      </React.Fragment>
    </Suspense>
  </Router>
);

ReactDOM.render(
  routing,
  document.getElementById('root')
);
